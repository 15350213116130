import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import { askQuestion } from '../api';
import { FaPaperPlane, FaRobot, FaUser, FaDownload } from 'react-icons/fa';
import { useTable } from 'react-table';
import { Box, Typography, TextField, Select, MenuItem, Button, Paper } from '@mui/material';

function CsvTable({ csvContent }) {
  const csvRows = useMemo(() => {
    try {
      return csvContent.trim().split('\n').map(row => row.split(','));
    } catch (error) {
      console.error('Error parsing CSV content:', error);
      return [];
    }
  }, [csvContent]);

  const headers = useMemo(() => csvRows[0] || [], [csvRows]);
  const data = useMemo(() => csvRows.slice(1), [csvRows]);

  const columns = useMemo(
    () => headers.map((header, index) => ({
      Header: header,
      accessor: `col${index}`,
    })),
    [headers]
  );

  const tableData = useMemo(
    () => data.map(row => {
      const rowData = {};
      row.forEach((cell, index) => {
        rowData[`col${index}`] = cell;
      });
      return rowData;
    }),
    [data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: tableData });

  return (
    <Box sx={{ overflowX: 'auto', maxWidth: '100%' }}>
      <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} style={{ padding: '8px', borderBottom: '2px solid #ddd' }}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
}

function ChatSection({ sessionId, showNotification }) {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [responseType, setResponseType] = useState('fast');
  const [isLoading, setIsLoading] = useState(false);
  const chatDisplayRef = useRef(null);

  useEffect(() => {
    setMessages([]);
  }, [sessionId]);

  useEffect(() => {
    if (chatDisplayRef.current) {
      chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim() || isLoading) return;

    const userMessage = { text: input, sender: 'user' };
    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setIsLoading(true);

    try {
      const response = await askQuestion(sessionId, input, responseType);
      const aiMessage = { 
        text: response.response || response.introduction,
        sender: 'ai',
        responseType,
        data: response
      };
      console.log("AI Message:", aiMessage);
      setMessages(prev => [...prev, aiMessage]);
    } catch (error) {
      console.error('Error asking question:', error);
      showNotification('Failed to get response', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = useCallback((fileType, fileName, content) => {
    let blob;
    let fileExtension;

    if (fileType === 'image') {
      blob = base64ToBlob(content, 'image/png');
      fileExtension = 'png';
    } else if (fileType === 'csv') {
      blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
      fileExtension = 'csv';
    } else {
      console.error('Unsupported file type');
      return;
    }

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.${fileExtension}`;
    link.click();
    URL.revokeObjectURL(link.href);
  }, []);

  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  const renderFastResponse = (message) => (
    <Box>
      <ReactMarkdown>{message.text}</ReactMarkdown>
      <Typography variant="h6" sx={{ mt: 2 }}>Sources:</Typography>
      <ul>
        {message.data.sources && message.data.sources.map((source, index) => (
          <li key={index}>{source}</li>
        ))}
      </ul>
    </Box>
  );

  const renderDetailedResponse = (message) => (
    <Box>
      <ReactMarkdown>{message.text}</ReactMarkdown>
      {renderGeneratedFiles(message.data.data)}
      <Typography variant="h6" sx={{ mt: 2 }}>Sources:</Typography>
      <ul>
        {message.data.sources && message.data.sources.map((source, index) => (
          <li key={index}>{source}</li>
        ))}
      </ul>
    </Box>
  );

  const renderReportResponse = (message) => {
    console.log("Rendering report response:", message);
    const report = message.data.response;
    if (!report) {
      console.error("Report data is missing");
      return <Typography>Error: Report data is missing</Typography>;
    }

    const renderSection = (section, depth = 0) => {
      return (
        <Box key={section.section_number} sx={{ ml: depth * 2, mb: 2 }}>
          <Typography variant={`h${Math.min(depth + 4, 6)}`} sx={{ mb: 1 }}>
            {section.section_number}. {section.section_title}
          </Typography>
          <ReactMarkdown>{section.section_text}</ReactMarkdown>
          {renderGeneratedFiles(section.section_data)}
          {section.subsections && section.subsections.map(subsection => renderSection(subsection, depth + 1))}
          {section.section_sources && (
            <>
              <Typography variant="h6" sx={{ mt: 1 }}>Sources:</Typography>
              <ul>
                {section.section_sources.map((source, index) => (
                  <li key={index}>{source}</li>
                ))}
              </ul>
            </>
          )}
        </Box>
      );
    };

    return (
      <Box>
        <Typography variant="h4">{report.title}</Typography>
        <ReactMarkdown>{report.introduction || 'No introduction provided.'}</ReactMarkdown>
        {report.sections && report.sections.map(section => renderSection(section))}
        <Typography variant="h5" sx={{ mt: 3 }}>Conclusion</Typography>
        <ReactMarkdown>{report.conclusion || 'No conclusion provided.'}</ReactMarkdown>
      </Box>
    );
  };

  const renderGeneratedFiles = useCallback((data) => {
    if (!data) return null;

    return (
      <Box sx={{ mt: 2 }}>
        {data.figures && data.figures.map((figure, index) => (
          <Box key={`img-${index}`} sx={{ mb: 2 }}>
            <img 
              src={`data:image/png;base64,${figure}`} 
              alt={`Generated figure ${index + 1}`}
              style={{ maxWidth: '100%', height: 'auto' }}
            />
            <Button 
              variant="contained"
              startIcon={<FaDownload />}
              onClick={() => handleDownload('image', `figure_${index + 1}`, figure)}
              sx={{ mt: 1 }}
            >
              Download Figure
            </Button>
          </Box>
        ))}
        {data.tables && data.tables.map((table, index) => (
          <Box key={`csv-${index}`} sx={{ mb: 2 }}>
            <Typography variant="h6">Table {index + 1}</Typography>
            <CsvTable csvContent={atob(table)} />
            <Button 
              variant="contained"
              startIcon={<FaDownload />}
              onClick={() => handleDownload('csv', `table_${index + 1}`, atob(table))}
              sx={{ mt: 1 }}
            >
              Download Table
            </Button>
          </Box>
        ))}
      </Box>
    );
  }, [handleDownload]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: '700px' }}>
      <Typography variant="h5" sx={{ mb: 2 }}>Chat: {sessionId}</Typography>
      <Paper 
        elevation={3} 
        ref={chatDisplayRef}
        sx={{ 
          flexGrow: 1, 
          overflowY: 'auto', 
          p: 2, 
          mb: 2, 
          minHeight: '300px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {messages.length === 0 ? (
          <Typography sx={{ alignSelf: 'center', justifySelf: 'center', color: 'text.secondary' }}>
            No messages yet. Start a conversation!
          </Typography>
        ) : (
          messages.map((msg, index) => (
            <Box 
              key={index} 
              sx={{ 
                display: 'flex', 
                justifyContent: msg.sender === 'user' ? 'flex-end' : 'flex-start',
                mb: 2
              }}
            >
              <Paper 
                elevation={1}
                sx={{ 
                  p: 2, 
                  maxWidth: '70%', 
                  backgroundColor: msg.sender === 'user' ? 'primary.light' : 'grey.100',
                  textAlign: msg.sender === 'ai' ? 'left' : 'right'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, justifyContent: msg.sender === 'user' ? 'flex-end' : 'flex-start' }}>
                  {msg.sender === 'user' ? <FaUser /> : <FaRobot />}
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {msg.sender === 'user' ? 'You' : 'AI'}
                  </Typography>
                </Box>
                {msg.sender === 'ai' ? (
                  msg.responseType === 'fast' ? renderFastResponse(msg) :
                  msg.responseType === 'detailed' ? renderDetailedResponse(msg) :
                  msg.responseType === 'report' ? renderReportResponse(msg) :
                  <Typography>{msg.text}</Typography>
                ) : (
                  <Typography>{msg.text}</Typography>
                )}
              </Paper>
            </Box>
          ))
        )}
        {isLoading && (
          <Box sx={{ alignSelf: 'center', justifySelf: 'center' }}>
            <Typography>Thinking...</Typography>
          </Box>
        )}
      </Paper>
      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', gap: 1 }}>
        <TextField
          fullWidth
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Enter your query here"
          disabled={isLoading}
          variant="outlined"
          size="small"
        />
        <Select
          value={responseType}
          onChange={(e) => setResponseType(e.target.value)}
          disabled={isLoading}
          size="small"
          sx={{ minWidth: 120 }}
        >
          <MenuItem value="fast">Fast</MenuItem>
          <MenuItem value="detailed">Detailed</MenuItem>
          <MenuItem value="report">Report</MenuItem>
        </Select>
        <Button 
          type="submit" 
          variant="contained" 
          disabled={isLoading}
          startIcon={<FaPaperPlane />}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
}

export default ChatSection;