import axios from 'axios';
import { auth } from './firebase';

const API_BASE_URL = 'https://europe-west2-bimbackend-dbe70.cloudfunctions.net/ask_question';

const api = axios.create({
  baseURL: API_BASE_URL,
});

const handleApiError = (error) => {
  if (error.response) {
    throw new Error(error.response.data.message || 'An error occurred');
  } else if (error.request) {
    throw new Error('No response from server. Please check your internet connection.');
  } else {
    throw new Error('An unexpected error occurred');
  }
};

export const askQuestion = async (sessionId, question, responseType) => {
  try {
    const idToken = await getIdToken();
    const response = await api.post('/ask_question', 
      { session_id: sessionId, question, response_type: responseType },
      { headers: { Authorization: `Bearer ${idToken}` } }
    );
    console.log("response: ", response.data);
    return response.data;
  } catch (error) {
    console.log("error: ", error);
    handleApiError(error);
  }
};

async function getIdToken() {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not authenticated');
  }
  return user.getIdToken();
}